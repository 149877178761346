.ms-button {
    font-family: "Segoe UI", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    background-color: #2f2f2f;
    height: 41px;
    padding: 0 12px;
    border: solid 1px #2f2f2f;
    display: inline-flex;
    align-items: center;

    &:hover {
        color: #5e5e5e;
        background-color: #fff;
        border-color: #8c8c8c;
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    svg {
        margin-right: 12px;
    }
}
